import React from 'react';
import { Box, Container, Typography, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Verify = () => {
  const navigate = useNavigate();

  const handleResend = () => {
    console.log('Resend');
  };

  return (
    <Box sx={{ py: 4, bgcolor: 'background.default', minHeight: '100vh' }}>
      <Container maxWidth="sm">
        <Typography variant="h4" component="h1" align="center" gutterBottom sx={{ mb: 4 }}>
          Verify Your Email
        </Typography>
        <Paper elevation={3} sx={{ 
          p: 4, 
          borderRadius: 2, 
          bgcolor: 'background.paper',
          '&:hover': { 
            boxShadow: 6
          } 
        }}>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            We have sent you a verification link on email
          </Typography>
          <Box sx={{ 
            mt: 4,
            textAlign: 'center',
            paddingTop: 2,
            borderTop: 1,
            borderColor: 'divider'
          }}>
            <Typography variant="body2" color="text.secondary">
              Didn't receive the email?{' '}
              <Button 
                color="primary" 
                onClick={handleResend}
                sx={{ 
                  textTransform: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Resend
              </Button>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Verify;