import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        accessToken: Cookies.get('accessToken'),
        refreshToken: Cookies.get('refreshToken'),
    });

    useEffect(() => {
        const updateAuthFromCookies = () => {
            const accessToken = Cookies.get('accessToken');
            const refreshToken = Cookies.get('refreshToken');
            setAuth({ accessToken, refreshToken });
        };

        const handleStorageChange = (event) => {
            if (event.key === 'accessToken' || event.key === 'refreshToken') {
                updateAuthFromCookies();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        updateAuthFromCookies();

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const updateAuth = (accessToken, refreshToken) => {
        if (accessToken) {
            Cookies.set('accessToken', accessToken);
        } else {
            Cookies.remove('accessToken');
        }
        
        if (refreshToken) {
            Cookies.set('refreshToken', refreshToken);
        } else {
            Cookies.remove('refreshToken');
        }

        setAuth({ accessToken, refreshToken });

        window.dispatchEvent(new Event('storage'));
    };

    return (
        <AuthContext.Provider value={{ auth, updateAuth }}>
            {children}
        </AuthContext.Provider>
    );
};