import React, { useState } from 'react';
import { Box, Container, TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel, Paper, IconButton, InputAdornment, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { accountService } from '../api/account';

const Signup = () => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
		firmName: '',
		phoneNumber: '',
		profession: '',
		servicePlan: 'Legal',
		firmSize: '',
	});

	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const navigate = useNavigate();

	const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	const validatePassword = (password) => {
		const minLength = 8;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumbers = /\d/.test(password);
		const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

		if (password.length < minLength) return 'Password must be at least 8 characters long';
		if (!hasUpperCase) return 'Password must contain at least one uppercase letter';
		if (!hasLowerCase) return 'Password must contain at least one lowercase letter';
		if (!hasNumbers) return 'Password must contain at least one number';
		if (!hasSpecialChar) return 'Password must contain at least one special character';
		return '';
	};

	const validateForm = (name, value) => {
		let newErrors = { ...errors };

		switch (name) {
			case 'firstName':
			case 'lastName':
				newErrors[name] = value.length < 2 ? 'Must be at least 2 characters' : '';
				break;
			case 'email':
				newErrors.email = !value ? 'Email is required' : !validateEmail(value) ? 'Invalid email address' : '';
				break;
			case 'password':
				newErrors.password = validatePassword(value);
				if (formData.confirmPassword) {
					newErrors.confirmPassword = value !== formData.confirmPassword ? 'Passwords do not match' : '';
				}
				break;
			case 'confirmPassword':
				newErrors.confirmPassword = value !== formData.password ? 'Passwords do not match' : '';
				break;
			case 'firmName':
				newErrors.firmName = value.length < 2 ? 'Firm name is required' : '';
				break;
			case 'phoneNumber':
				const phoneRegex = /^\+?[\d\s-]{10,}$/;
				newErrors.phoneNumber = value && !phoneRegex.test(value) ? 'Invalid phone number' : '';
				break;
			case 'firmSize':
				newErrors.firmSize = value.length < 1 ? 'Firm size is required' : '';
				break;
			case 'profession':
				newErrors.profession = value.length < 2 ? 'Profession is required' : '';
				break;
			default:
				break;
		}

		setErrors(newErrors);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData(prev => ({ ...prev, [name]: value }));
		validateForm(name, value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const allFields = ['firstName', 'lastName', 'email', 'password', 'confirmPassword', 'firmName', 'firmSize', 'profession'];
		allFields.forEach(field => validateForm(field, formData[field]));

		const hasErrors = Object.values(errors).some(error => error !== '');
		if (!hasErrors && allFields.every(field => formData[field])) {
			try {
				const response = await accountService.signup(formData);
				console.log('Signup successful:', response.data);
				navigate('/signup/verify');
			} catch (error) {
				console.error('Signup failed:', error);
				navigate('/signup/verify');
			}
		}
	};

	return (
		<Box sx={{ py: 4, bgcolor: 'background.default', minHeight: '100vh' }}>
			<Container maxWidth="sm">
				<Typography variant="h4" component="h1" align="center" gutterBottom sx={{ mb: 4 }}>
					Sign up
				</Typography>
				<Paper elevation={3} sx={{ 
					p: 4, 
					borderRadius: 2, 
					bgcolor: 'background.paper',
					'&:hover': { 
						boxShadow: 6
					} 
				}}>
					<Box 
						component="form" 
						onSubmit={handleSubmit}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 3
						}}
					>
						<TextField 
							required 
							name="firstName" 
							label="First Name" 
							value={formData.firstName} 
							onChange={handleChange} 
							error={!!errors.firstName} 
							helperText={errors.firstName} 
							fullWidth
							autoComplete='off'
						/>
						<TextField 
							required 
							name="lastName" 
							label="Last Name" 
							value={formData.lastName} 
							onChange={handleChange} 
							error={!!errors.lastName} 
							helperText={errors.lastName} 
							fullWidth 
							autoComplete='off'
						/>
						<TextField 
							required 
							name="email" 
							label="Email" 
							type="email" 
							value={formData.email} 
							onChange={handleChange} 
							error={!!errors.email} 
							helperText={errors.email} 
							fullWidth 
							autoComplete='off'
						/>
						<TextField 
							required 
							name="password" 
							label="Password" 
							type={showPassword ? 'text' : 'password'} 
							value={formData.password} 
							onChange={handleChange} 
							error={!!errors.password} 
							helperText={errors.password}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
							fullWidth 
						/>
						<TextField 
							required 
							name="confirmPassword" 
							label="Confirm Password" 
							type={showConfirmPassword ? 'text' : 'password'} 
							value={formData.confirmPassword} 
							onChange={handleChange} 
							error={!!errors.confirmPassword} 
							helperText={errors.confirmPassword}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
											{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
							fullWidth 
						/>
						<TextField 
							required 
							name="firmName" 
							label="Firm Name" 
							value={formData.firmName} 
							onChange={handleChange} 
							error={!!errors.firmName} 
							helperText={errors.firmName} 
							fullWidth 
							autoComplete='off'
						/>
						<TextField 
							name="phoneNumber" 
							label="Phone Number" 
							value={formData.phoneNumber} 
							onChange={handleChange} 
							error={!!errors.phoneNumber} 
							helperText={errors.phoneNumber} 
							fullWidth
							autoComplete='off'
						/>
						<TextField 
							name="profession" 
							label="Profession" 
							value={formData.profession} 
							onChange={handleChange} 
							error={!!errors.profession} 
							helperText={errors.profession} 
							fullWidth
							autoComplete='off'
						/>
						<FormControl fullWidth>
							<InputLabel id="service-plan-label">Service Plan</InputLabel>
							<Select
								labelId="service-plan-label"
								name="servicePlan"
								value={formData.servicePlan}
								onChange={handleChange}
								label="Service Plan"
							>
								<MenuItem value="Default">Default</MenuItem>
								<MenuItem value="Legal">Legal</MenuItem>
							</Select>
						</FormControl>
						<TextField 
							required 
							name="firmSize" 
							label="Firm Size" 
							value={formData.firmSize} 
							onChange={handleChange} 
							error={!!errors.firmSize} 
							helperText={errors.firmSize} 
							fullWidth
							autoComplete='off'
						/>
						<Button 
							type="submit" 
							variant="contained" 
							fullWidth 
							sx={{ 
								mt: 2,
								height: '48px', 
								fontSize: '1.1rem',
								color: (theme) => theme.palette.mode === 'light' ? 'white' : 'black'
							}}
						>
							Submit
						</Button>
					</Box>
					<Box sx={{ 
						mt: 4,
						textAlign: 'center',
						paddingTop: 2,
						borderTop: 1,
						borderColor: 'divider'
					}}>
						<Typography variant="body2" color="text.secondary">
							Already have an account?{' '}
							<Link 
								component={RouterLink} 
								to="/login" 
								sx={{ 
									textDecoration: 'none',
									color: 'primary.main',
									'&:hover': {
										textDecoration: 'underline'
									}
								}}
							>
								Login
							</Link>
						</Typography>
					</Box>
				</Paper>
			</Container>
		</Box>
	);
};

export default Signup;