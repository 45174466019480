import axios from 'axios';
import Cookies from 'js-cookie';
import accountService from './account';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

axiosClient.interceptors.request.use((config) => {
  const accessToken = Cookies.get('accessToken');

  if (config.url !== '/auth/login' && accessToken) {
    config.headers['Authorization'] = `${accessToken}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosClient.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const accessToken = Cookies.get('accessToken');
        const refreshToken = Cookies.get('refreshToken');

        if (accessToken && refreshToken) {
          const response = await accountService.refreshToken(accessToken, refreshToken);

          const { accessToken: newAccessToken } = response.data;

          Cookies.set('accessToken', newAccessToken);

          axiosClient.defaults.headers['Authorization'] = `${newAccessToken}`;

          originalRequest.headers['Authorization'] = `${newAccessToken}`;
          return axiosClient(originalRequest);
        }
      } catch (refreshError) {
        console.error('Refresh token failed:', refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosClient;