import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, IconButton, Badge, Avatar, Menu, MenuItem, ListItemIcon } from '@mui/material';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import accountService from '../api/account';

import { 
    NotificationsNone as NotificationIcon,
    Menu as MenuIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    KeyboardArrowDown as ArrowDownIcon 
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Logo from '../static/img/logo.png';
import { AuthContext } from '../api/AuthContext';

const CACHE_KEY = 'accountNameCache';
const CACHE_DURATION = 6 * 60 * 60 * 1000; // 6 hours in milliseconds

const getCachedAccountName = () => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;

    try {
        const { value, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp > CACHE_DURATION) {
            localStorage.removeItem(CACHE_KEY);
            return null;
        }
        return value;
    } catch (error) {
        localStorage.removeItem(CACHE_KEY);
        return null;
    }
};

const cacheAccountName = (name) => {
    const cacheData = {
        value: name,
        timestamp: Date.now()
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const AppBar = ({ isMobile, onMenuClick }) => {
    const { updateAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [accountName, setAccountName] = useState(() => getCachedAccountName() || '');

    useEffect(() => {
        const fetchAccountName = async () => {
            // First check if we have a valid cached name
            const cachedName = getCachedAccountName();
            if (cachedName) {
                setAccountName(cachedName);
                return; // Exit early if we have a cached name
            }

            // Only proceed with API call if we don't have a cached name
            const accessToken = Cookies.get('accessToken');
            if (!accessToken) return;

            try {
                const decodedToken = jwtDecode(accessToken);
                if (decodedToken.userid) {
                    const response = await accountService.getUserInfo(decodedToken.userid);
                    const fullName = `${response.data.first_name} ${response.data.last_name}`;
                    setAccountName(fullName);
                    cacheAccountName(fullName);
                }
            } catch (error) {
                console.error("Error fetching account name", error);
            }
        };

        // Only fetch if we don't have a name already (either from cache or state)
        if (!accountName) {
            fetchAccountName();
        }
    }, []); // Run once on component mount

    const handleLogout = () => {
        localStorage.removeItem(CACHE_KEY); // Clear the cache on logout
        setAccountName(''); // Clear the state as well
        updateAuth(null, null);
        handleClose();
        navigate('/login');
    };

    // Rest of the component remains the same
    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSettingsClick = () => {
        navigate('/settings');
        handleClose();
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 2,
            px: 2,
            width: '100%',
            boxSizing: 'border-box',
            minWidth: 0,
            bgcolor: 'background.paper'
        }}>
            <Box 
                onClick={handleLogoClick}
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: { xs: 1, sm: 2 },
                    minWidth: 0,
                    cursor: 'pointer'
                }}
            >
                {isMobile && (
                    <IconButton onClick={(e) => {
                        e.stopPropagation();
                        onMenuClick();
                    }} sx={{ mr: 0 }}>
                        <MenuIcon />
                    </IconButton>
                )}
                <img src={Logo} alt="GoToBills Logo" style={{ height: '28px', width: 'auto' }} />
                <Typography 
                    variant="h4" 
                    fontWeight="bold"
                    sx={{ 
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    GoToBills
                </Typography>
            </Box>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 1, sm: 2 },
                minWidth: 0
            }}>
                <IconButton size="small" sx={{ color: 'text.primary' }}>
                    <Badge badgeContent={3} color="error">
                        <NotificationIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
                    </Badge>
                </IconButton>
                <Box 
                    onClick={handleProfileClick}
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1, 
                        cursor: 'pointer',
                        minWidth: 0
                    }}
                >
                    <Avatar sx={{ 
                        width: { xs: 28, sm: 32 }, 
                        height: { xs: 28, sm: 32 } 
                    }} />
                    <Typography 
                        variant="subtitle2" 
                        sx={{ 
                            display: { xs: 'none', sm: 'block' },
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: 150
                        }}
                    >
                        {accountName || 'Account Name'}
                    </Typography>
                    <ArrowDownIcon sx={{ fontSize: { xs: 16, sm: 20 } }} />
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
                            mt: 1.5,
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={handleSettingsClick}>
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    );
};

export default AppBar;