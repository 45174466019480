import axiosClient from './axios';

export const accountService = {
  signup: async (userData) => {
    try {
      return await axiosClient.post('/firm/signup', JSON.stringify({
        first_name: userData.firstName,
        last_name: userData.lastName,
        email: userData.email,
        firm_name: userData.firmName,
        phone_number: userData.phoneNumber,
        password: userData.password,
        service_plan_code: userData.servicePlan.toLowerCase(),
        firm_size: userData.firmSize,
        profession: userData.profession,
      }));
    } catch (e) {
      console.log(e);
    }
  },

  getUserInfo: async (userid) => {
    return await axiosClient.get(`/firmuser/${userid}`);
  },

  login: async (email, password) => {
    return await axiosClient.post('/auth/login', JSON.stringify({ email, password }));
  },

  refreshToken: async (refreshToken) => {
    try {
      return await axiosClient.post('/auth/refresh', JSON.stringify({ refreshToken }));
    } catch (e) {
      console.log(e);
    }
  },
};

export default accountService;