import React, { useContext, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoutes = ({ element: Component, ...rest }) => {
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.accessToken) {
            navigate('/login');
        }
    }, [auth.accessToken, navigate]);

    return auth.accessToken ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;